
import { Options, Vue } from "vue-property-decorator";
import * as api from "@/api/videoInspection";
import File from "@/api/file";
import aliplayer from "@/components/aliplayer/index.vue";

@Options({
  components: {
    aliplayer,
  },
})
export default class extends Vue {
  infodata: any = { dealList: [{ status: 0 }], flag: 0, id: 0 };
  active = -1;
  show = false;
  previewimg = "";
  remark = "";
  uploader: any[] = [];
  checkUploader: any[] = [];
  options: any = {};
  checkId: any = "";

  created() {
    this.checkId = this.$route.query.id;
    this.initview();
  }

  public previewimgfun(arg: any) {
    this.previewimg = arg;
    this.show = true;
  }

  protected afterRead(files: any) {
    let filearr = null;
    if (!Array.isArray(files)) {
      filearr = [files];
    } else {
      filearr = [...files];
    }
    filearr.map((file: any) => {
      file.status = "uploading";
      file.message = "上传中...";
      File.uploadFile(
        "photographmobile/",
        file.file.name,
        file.file,
        true
      ).then((res: any) => {
        file.status = "done";
        file.id = res.data.id;
      });
    });
  }

  initview() {
    api
      .getVideoCheckDetail({
        checkId: this.checkId,
      })
      .then((res: any) => {
        this.infodata = res.data;
        this.options = {
          autoplay: false,
          source: res.data.checkVideo,
        };
      });
  }

  onSubmit() {
    this.$dialog.confirm({
      title: "是否确认提交？",
      message: "",
      beforeClose: (action: string) => {
        if (action === "confirm") {
          let arr = "";
          this.uploader.map((item) => {
            arr += "," + item.id;
          });
          let checkArr = "";
          this.checkUploader.map((item) => {
            checkArr += "," + item.id;
          });
          const data = {
            checkId: this.infodata.id,
            remark: this.remark,
            fileIds: arr.substr(1, arr.length - 1),
            checkFileIds: checkArr.substr(1, checkArr.length - 1),
            status: 2,
          };
          api.dealAbnormalCheck(data).then((res: any) => {
            if (res.code === 0) {
              this.$toast.success({
                message: res.msg,
                forbidClick: true,
                onClose: () => {
                  this.$router.go(-1);
                },
              });
            }
          });
        }
        return true;
      },
    });
  }

  examine(type: any) {
    if (this.remark === "") {
      this.$toast.fail({
        message: "请填写审核意见",
      });
    } else {
      let data = {};
      let arr = "";
      this.uploader.map((item) => {
        arr += "," + item.id;
      });
      let checkArr = "";
      this.checkUploader.map((item) => {
        checkArr += "," + item.id;
      });
      if (type === 1) {
        data = {
          checkId: this.infodata.id,
          remark: this.remark,
          fileIds: arr.substr(1, arr.length - 1),
          checkFileIds: checkArr.substr(1, checkArr.length - 1),
          status: 3,
        };
      } else {
        data = {
          checkId: this.infodata.id,
          remark: this.remark,
          fileIds: arr.substr(1, arr.length - 1),
          checkFileIds: checkArr.substr(1, checkArr.length - 1),
          status: 1,
        };
      }
      this.$dialog.confirm({
        title: "是否确认" + (type === 1 ? "通过" : "不通过") + "？",
        message: "",
        beforeClose: (action: any) => {
          if (action === "confirm") {
            api.dealAbnormalCheck(data).then((res: any) => {
              if (res.code === 0) {
                this.$toast.success({
                  message: res.msg,
                  forbidClick: true,
                  onClose: () => {
                    this.$router.go(-1);
                  },
                });
              }
            });
          }
          return true;
        },
      });
    }
  }

  statusImg(val: any) {
    if (val === 1) {
      return require(`@/assets/videoInspection/normal.png`);
    }
    if (val === 2) {
      return require(`@/assets/videoInspection/abnormal.png`);
    }
  }
}
