import request from "@/utils/request";

// 视频巡检列表
export function pageAll(data: any) {
  return request({
    url: "/project/videoCheck/pageAll",
    method: "get",
    params: data,
  });
}

// 可用设备列表
export function listUsableDevice(data: any) {
  return request({
    url: "/project/videoDevice/listUsableDevice",
    method: "get",
    params: data,
  });
}

// 开始巡检
export function startVideoCheck(data: any) {
  return request({
    url: "/project/videoCheck/startVideoCheck",
    method: "post",
    data: data,
  });
}

// 视频巡检详情
export function getVideoCheckDetail(data: any) {
  return request({
    url: "/project/videoCheck/getVideoCheckDetail",
    method: "get",
    params: data,
  });
}

// 视频文件列表
export function getDeviceVideoByTime(data: any) {
  return request({
    url: "/project/videoCheck/getDeviceVideoByTime",
    method: "get",
    params: data,
  });
}

// 结束巡检
export function stopVideoCheck(data: any) {
  return request({
    url: "/project/videoCheck/stopVideoCheck",
    method: "post",
    data: data,
  });
}

// 处理异常巡检
export function dealAbnormalCheck(data: any) {
  return request({
    url: "/project/videoCheck/dealAbnormalCheck",
    method: "put",
    data: data,
  });
}
